import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 style={{
                listStyle: 'none',
                display: 'flex',
                justifyContent: 'space-evenly'
            }}>Welcome to Millsap Small Engine Repair. </h1>

    <div className="home">
      <p>
        Millsap Small Engine Repair specializes in small engine repair and maintenance.
        For decades, we have been the go-to shop for those who are in need of
        skilled, experienced mechanics. As a
        hometown service company, we value the satisfaction of every
        client we serve. We always strive to provide excellent customer service.</p>
        <br />
        <p>At Millsap Small Engine Repair can service all kinds of lawn
        equipment, atv's, and other small engines that you
        may have. Whether you have a decade old lawn mower that requires
        complete overhaul or a newly purchased generator that you are having trouble with, we can provide the right solutions. 
        The maintenance tasks that we offer include air filter and oil replacement, blade replacement and
        adjustment, and engine tune up.
      </p>
      <h5>
      Please call Millsap Small Engine Repair at (940) 682-4724 or visit our shop in Mineral Wells, TX.
      </h5>
    </div>
    <hr />
    <div>
      <p style={{
                listStyle: 'none',
                display: 'flex',
                justifyContent: 'space-evenly'
            }}>We Provide Services to the following Cities and Towns:</p>
      <ul>
        <li>Mineral Wells</li>
        <li>Weatherford</li>
        <li>Azle</li>
        <li>Stephenville</li>
        <li>Jacksboro</li>
        <li>Eastland</li>
      </ul>
    </div>
    <hr/>
    {/* <p>Now go build something great.</p> */}
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
